import React from 'react';
import classNames from 'classnames';
import { NavLink as RouterNavLink, Link } from 'react-router-dom';

import { format } from '@/services';
import { useNavigate } from '@/hooks';
import { useAppContext } from '@/context';

import Brand from './Brand';
import NavToggler from './NavToggler';

import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from 'reactstrap';

import './NavMenu.scss';

import settings from '@/settings';
const { enableFeatures } = settings;

export default function NavMenu() {
  const { navCollapsed, setNavCollapsed, phoneNumber, showPlaceOrder } =
    useAppContext();
  const navigate = useNavigate();

  function toggleNavCollapsed() {
    setNavCollapsed((value) => !value);
  }

  return (
    <header className="mb-n3 shadow sticky-top">
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm bg-white border-bottom mb-3 px-md-5 px-lg-6"
        light>
        <NavbarBrand>
          <Brand />
        </NavbarBrand>
        <NavbarToggler
          tag={NavToggler}
          onClick={toggleNavCollapsed}
          collapsed={navCollapsed}
          className="mr-2"
        />
        <Collapse
          className="d-md-inline-flex justify-content-end"
          isOpen={!navCollapsed}
          navbar>
          <ul className="navbar-nav flex-grow text-center">
            {phoneNumber && (
              <NavItem className="pl-sm-2">
                <NavLink
                  className="color-secondary"
                  href={`tel:${format.phoneNumber(phoneNumber, 'plain')}`}>
                  <i className="icon-phone"></i>{' '}
                  {format.phoneNumber(phoneNumber, 'noparens')}
                </NavLink>
              </NavItem>
            )}
            {showPlaceOrder && (
              <NavItem className="pl-sm-2">
                <NavLink tag={Link} to={navigate.getPlaceOrderUrl(3)}>
                  Place Order
                </NavLink>
              </NavItem>
            )}
            {enableFeatures?.includes('videos') && (
              <NavItem
                className={classNames('pl-sm-2 d-sm-none d-md-list-item')}>
                <NavLink tag={RouterNavLink} to={navigate.getVideosUrl()}>
                  Videos
                </NavLink>
              </NavItem>
            )}
            {enableFeatures?.includes('blog') && (
              <NavItem className={classNames('pl-sm-2')}>
                <NavLink tag={RouterNavLink} to={navigate.getBlogUrl()}>
                  Blog
                </NavLink>
              </NavItem>
            )}
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );
}

NavMenu.displayName = NavMenu.name;
