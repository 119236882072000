import React, { useState } from 'react';

import { useNavigate, useLoad } from '@/hooks';
import { Alert, Button, Skeleton } from '@/components';
import { httpRequest, format, utility } from '@/services';
import { Row, Col, Popover, PopoverBody } from 'reactstrap';

const { getPublicFilePath } = utility;

export default function LocationList() {
  const [locations, setLocations] = useState();

  const {
    status: { isLoading, error },
  } = useLoad(() => {
    return httpRequest.get('api/locations').then(setLocations);
  });

  return (
    <div className="mt-5">
      {error ? (
        <Alert
          vertical
          type="warning"
          className="w-sm-75 mx-auto"
          messageClass="text-center">
          We're sorry, but we're having a problem loading the convention venues.
          Please try again later.
        </Alert>
      ) : isLoading ? (
        <LocationItem isLoading />
      ) : (
        locations &&
        locations.map((location) => (
          <LocationItem key={location.locationID} location={location} />
        ))
      )}
    </div>
  );
}

function LocationItem({ isLoading, location = {} }) {
  const navigate = useNavigate();
  const [formsPopoverOpen, setFormsPopoverOpen] = useState(false);

  return (
    <Row key={location.locationID} className="align-items-md-center mb-4">
      <Col xs={18} lg={9} className="text-center text-lg-right">
        {isLoading ? (
          <Skeleton height={250} width={350} />
        ) : (
          location.venueImageFileName && (
            <img
              className="img-fluid shadow-lg-offset-l"
              src={getPublicFilePath(location.venueImageFileName)}
              alt={location.locationName}
              text={location.locationName}
            />
          )
        )}
      </Col>
      <Col className="text-center text-lg-left mt-4 mt-lg-0">
        {isLoading ? (
          <Skeleton height={150} width={250} />
        ) : (
          <img
            className="img-fluid"
            style={{ width: '50%', maxWidth: '20rem' }}
            src={getPublicFilePath(location.logoFileName)}
            alt={location.locationName}
            text={location.locationName}
          />
        )}
        <Row>
          <Col>
            {isLoading ? (
              <Skeleton width={300} type="address" className="mt-2" />
            ) : (
              <address className="mt-4">
                {format.address(location.address)}
              </address>
            )}
          </Col>
        </Row>
        <Row noGutters className="mt-1">
          <Col lg="auto">
            {isLoading ? (
              <Skeleton type="button" />
            ) : (
              <Button
                color="primary"
                onClick={() => navigate.toPlaceOrder(location.locationID)}>
                Place Order
              </Button>
            )}
          </Col>
          <Col className="mx-auto mx-lg-0 ml-lg-3">
            {isLoading ? (
              <Skeleton type="button" />
            ) : (
              <>
                <Button
                  color="link"
                  id={`forms-button_${location.locationID}`}
                  tabIndex="0">
                  Forms
                </Button>
                <Popover
                  trigger="legacy"
                  placement="top"
                  target={`forms-button_${location.locationID}`}
                  isOpen={formsPopoverOpen}
                  toggle={() => setFormsPopoverOpen(!formsPopoverOpen)}>
                  <PopoverBody>
                    <ul className="list-unstyled">
                      {location.dataOrderFormFileName && (
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getPublicFilePath(
                              location.dataOrderFormFileName
                            )}>
                            Data Order Form
                          </a>
                        </li>
                      )}
                      {location.voiceVideoOrderFormFileName && (
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getPublicFilePath(
                              location.voiceVideoOrderFormFileName
                            )}>
                            Voice/Video Order Form
                          </a>
                        </li>
                      )}
                      {location.wifiHotspotOrderFormFileName && (
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getPublicFilePath(
                              location.wifiHotspotOrderFormFileName
                            )}>
                            WiFi Hotspot Order Form
                          </a>
                        </li>
                      )}
                    </ul>
                  </PopoverBody>
                </Popover>
              </>
            )}
            {isLoading ? (
              <Skeleton type="button" className="ml-3" />
            ) : (
              <Button
                color="link"
                onClick={() => navigate.toContact(location.locationID)}>
                Contact
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
