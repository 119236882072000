import React from 'react';
import classNames from 'classnames';

import { useTitle } from '@/hooks';
import { Row, Col } from 'reactstrap';
import LocationList from './LocationList';
import { Hero, BodyContainer } from '@/components';

import styles from './Home.module.scss';

export default function Home() {
  useTitle('Cox Convention Portal');

  return (
    <>
      {/* Top hero*/}
      <Row>
        <Col>
          <Hero className={classNames(styles['hero'])}>
            <Row className="h-100 align-items-md-center">
              <Col
                md={10}
                lg={9}
                xl={{ size: 8, offset: 2 }}
                className={classNames(
                  styles['copy'],
                  'text-center text-md-left px-sm-5 pl-md-5 pr-md-4'
                )}>
                <h3>
                  Welcome to the{' '}
                  <strong>
                    Las Vegas Convention Center internet and technology ordering
                    portal
                  </strong>{' '}
                  provided by{' '}
                  <span className="text-nowrap-lg">
                    Cox Business/Hospitality Network
                  </span>
                </h3>
              </Col>
              <Col className={classNames(styles['image'], 'snug-bottom')}></Col>
            </Row>
          </Hero>
        </Col>
      </Row>
      <BodyContainer>
        <Row className="justify-content-center">
          <Col xs={18} md={10}>
            {/* Medium call */}
            <h4 className="text-center">
              <span className="text-nowrap-sm">
                Cox Business/Hospitality Network
              </span>{' '}
              is the official Internet and WiFi provider at the Las Vegas
              Convention Center.
            </h4>
            <p className="text-center px-3">
              In addition to the wide range of connectivity products, Cox
              Business also provides TV and telephone services.
            </p>
          </Col>
        </Row>
      </BodyContainer>
      <LocationList />
    </>
  );
}
