import { useEffect, useRef } from 'react';
import { useAppContext } from '@/context';
import { useNavigate, useLocation } from 'react-router-dom';

const getContactUrl = (locationID) => `/location/${locationID}/contact`;

const getPlaceOrderUrl = (locationID, stepNumber = 1) =>
  `/location/${locationID}/order/step/${stepNumber}`;

const getVideosUrl = () => '/videos';

const getVideoPostUrl = (slug) => `/videos/${slug}`;

const getBlogPostUrl = (slug) => `/blog/${slug}`;

const getBlogUrl = ({ pageNumber, category } = {}) =>
  `/blog${category ? `/categories/${category.toLowerCase()}` : ''}${
    pageNumber ? `/page-${pageNumber}` : ''
  }`;

export default function _() {
  const { setNavCollapsed } = useAppContext();
  const isNavigating = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setNavCollapsed(true);
  }, [location, setNavCollapsed]);

  function doNavigate(action) {
    isNavigating.current = true;
    try {
      action();
    } finally {
      isNavigating.current = false;
    }
  }

  return {
    isNavigating: isNavigating.current,

    gotoUrl(url) {
      window.location.href = url;
    },

    gotoHash(hash) {
      doNavigate(() => {
        window.location.hash = hash;
      });
    },

    reload() {
      doNavigate(() => navigate(0));
    },

    goHome() {
      doNavigate(() => navigate('/'));
    },

    goBack() {
      doNavigate(() => navigate(-1));
    },

    getContactUrl,
    toContact(locationID) {
      doNavigate(() => navigate(getContactUrl(locationID)));
    },

    getPlaceOrderUrl,
    toPlaceOrder(locationID, stepNumber = 1) {
      doNavigate(() => navigate(getPlaceOrderUrl(locationID, stepNumber)));
    },

    getBlogUrl,
    toBlog(params) {
      doNavigate(() => navigate(getBlogUrl(params)));
    },

    getBlogPostUrl,
    toBlogPost(slug) {
      doNavigate(() => navigate(getBlogPostUrl(slug)));
    },

    getVideosUrl,
    toVideos() {
      doNavigate(() => navigate(getVideosUrl()));
    },

    getVideoPostUrl,
    toVideoPost(slug) {
      doNavigate(() => navigate(getVideoPostUrl(slug)));
    },
  };
}
