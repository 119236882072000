import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useLoad } from '@/hooks';
import { PricingTiers } from '@/context';
import { httpRequest, format } from '@/services';
import { Incrementor, ErrorMessage } from '@/components/form';
import { PlaceOrderContext } from '../context';


import {
  Hero,
  BodyContainer,
  Loader,
  FormSection,
  Tabs,
  AlternatingRows,
  TableContentLoader,
} from '@/components';

import {
  Row,
  Col,
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';

const { createTabItem } = Tabs;

export default function SelectServices(props) {
  const { orderState, updateService, isSubmitting, moveBack } =
    useContext(PlaceOrderContext);

  const [serviceInfo, setServiceInfo] = useState([]);
  const [topCategories, setTopCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const { locationID, eventID } = props;

  // Load all categories and services.
  const { status: loadStatus } = useLoad(() =>
    Promise.all([
      httpRequest(
        `api/location/${locationID}/event/${eventID}/service-categories`
      ).then((categories) => {
        setTopCategories(categories);
        if (categories && categories.length > 0) {
          setSelectedCategory(categories[0].categoryID);
        }
      }),
      httpRequest(`api/location/${locationID}/event/${eventID}/services`).then(
        setServiceInfo
      ),
    ])
  );

  // When a new tab is selected, change our state
  // to reflect the newly selected category.
  function handleSelectedCategory(item) {
    setSelectedCategory(item.key);
  }

  // In order to support real-time updating of the service
  // counts, we need to keep them somewhere other than in formik state.
  function handleQuantityChange({ serviceID, quantity }) {
    updateService({ [serviceID]: { quantity } });
  }

  // Get a count of distinct services per each top-level category.
  const serviceCounts = new Map();
  if (topCategories && serviceInfo.categories) {
    const { services: orderServices } = orderState;
    if (orderServices) {
      for (const topCategory of topCategories) {
        const subCategories = serviceInfo.categories.filter(
          (category) => category.topCategoryID === topCategory.categoryID
        );

        const subServiceIDs = subCategories
          .map((category) => category.services)
          .flat()
          .map((service) => service.serviceID);

        const count = Object.keys(orderServices).filter((serviceID) => {
          return (
            orderServices[serviceID].quantity > 0 &&
            subServiceIDs.includes(Number(serviceID))
          );
        }).length;

        serviceCounts.set(topCategory.categoryID, count);
      }
    }
  }

  // Get the sub-categories for the select top-level category.
  let activeCategories = [];
  if (serviceInfo.categories && selectedCategory) {
    activeCategories = serviceInfo.categories.filter(
      (cat) => cat.topCategoryID === selectedCategory
    );
  }

  const pricingTier = serviceInfo.pricingTier;

  return (
    <Loader {...loadStatus}>
      <Hero size="sm" className="shadow-sm px-4">
        <h2 className="text-medium">Select Services</h2>
        <p>
          Select the services you would like to order. Use the tabs below to
          jump to services.
          <br />
          <em>
            At least one service must be selected. The highlighted pricing is
            what's currently being offered.
          </em>
        </p>
        <Tabs
          className="mt-5 snug-bottom"
          selected={selectedCategory}
          onSelected={handleSelectedCategory}
          items={topCategories.map(({ categoryID, name }) =>
            createTabItem(categoryID, name, serviceCounts.get(categoryID) || 0)
          )}
        />
      </Hero>
      <BodyContainer>
        <TableContentLoader show={serviceInfo.length === 0}>
          {activeCategories.map((category) => (
            <FormSection key={category.categoryID} title={category.name}>
              <AlternatingRows secondaryClass="bg-light">
                {category.services.map((service) => (
                  <ServiceItem
                    key={service.serviceID}
                    service={service}
                    pricingTier={pricingTier}
                    onQuantityChange={handleQuantityChange}
                  />
                ))}
              </AlternatingRows>
            </FormSection>
          ))}
        </TableContentLoader>
        <ErrorMessage alwaysShow name="services" />
        <Row className="mt-4">
          <Col className="d-flex flex-wrap">
            <Button
              type="submit"
              color="primary"
              className="btn-form order-lg-2 mb-4 mb-lg-0 ml-lg-3"
              disabled={isSubmitting}>
              Next
            </Button>
            <Button
              outline
              type="button"
              color="primary"
              className="btn-form order-lg-1"
              onClick={moveBack}>
              Back
            </Button>
          </Col>
        </Row>
      </BodyContainer>
    </Loader>
  );
}

SelectServices.propTypes = {
  locationID: PropTypes.number.isRequired,
};

//
// ServiceItem
//

function ServiceItem(props) {
  const earlyPricingTipRef = useRef();
  const stdPricingTipRef = useRef();
  const latePricingTipRef = useRef();

  const { service, pricingTier } = props;

  function handleQuantityChange({ value }) {
    if (props.onQuantityChange) {
      props.onQuantityChange({ serviceID: service.serviceID, quantity: value });
    }
  }

  const isAdvancedPricing = pricingTier === PricingTiers.Advanced,
    isStandardPricing = pricingTier === PricingTiers.Standard,
    isLatePricing = pricingTier === PricingTiers.Late;

  return (
    <div className="py-3">
      <Row className="align-items-center flex-nowrap mx-n3">
        <Col className="flex-grow-1 pr-0">
          <span className="text-medium" title={service.name}>
            {service.name}
          </span>
        </Col>
        <Col className="flex-grow-0">
          {service.customerReadOnly ? (
            <strong>Call</strong>
          ) : (
            <Incrementor
              name={`services.${service.serviceID}.quantity`}
              onChange={handleQuantityChange}
              allowNegative={false}
            />
          )}
        </Col>
      </Row>
      {service.description && (
        <Row>
          <Col className="px-0">{service.description}</Col>
        </Row>
      )}
      <div className="d-flex flex-nowrap justify-content-between justify-content-md-start mt-3">
        {service.tbdPricing ? (
          <em>Please call for pricing.</em>
        ) : (
          <>
            <div className="mr-md-5">
              <span
                className={classNames({
                  'text-info font-weight-bold': isAdvancedPricing,
                  'color-medium': !isAdvancedPricing,
                })}>
                Advanced
                {isAdvancedPricing && (
                  <>
                    {' '}
                    <i className="icon-tooltip" ref={earlyPricingTipRef}></i>
                  </>
                )}
                <br />
                {format.currency(service.advancedPrice)}
              </span>
              {isAdvancedPricing && (
                <UncontrolledPopover
                  target={earlyPricingTipRef}
                  trigger="legacy"
                  placement="auto-end">
                  <PopoverHeader>
                    Your order qualifies for advanced pricing
                  </PopoverHeader>
                  <PopoverBody>
                    20% Early Ordering Discount – Final order and payment must
                    be received 30 days prior to the listed event start date.
                    Please note: A 20% Expedite Fee will be applied to any order
                    placed 72 hours or less before the listed event start date.
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </div>
            <div>
              <span
                className={classNames({
                  'text-info font-weight-bold': isStandardPricing,
                  'color-medium': !isStandardPricing,
                })}>
                Standard
                {isStandardPricing && (
                  <>
                    {' '}
                    <i className="icon-tooltip" ref={stdPricingTipRef}></i>
                  </>
                )}
                <br />
                {format.currency(service.standardPrice)}
              </span>
              {isStandardPricing && (
                <UncontrolledPopover
                  target={stdPricingTipRef}
                  trigger="legacy"
                  placement="auto-end">
                  <PopoverHeader>
                    Your order qualifies for standard pricing
                  </PopoverHeader>
                  <PopoverBody>
                    Standard Pricing – Your order qualifies for standard
                    pricing. Please note: A 20% Expedite Fee will be applied to
                    any order placed 72 hours or less before the listed event
                    start date.
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </div>
            <div className="ml-md-5">
              <span
                className={classNames({
                  'text-info font-weight-bold': isLatePricing,
                  'color-medium': !isLatePricing,
                })}>
                Move-In/On-Site
                {isLatePricing && (
                  <>
                    {' '}
                    <i className="icon-tooltip" ref={latePricingTipRef}></i>
                  </>
                )}
                <br />
                {format.currency(service.latePrice)}
              </span>
              {isLatePricing && (
                <UncontrolledPopover
                  target={latePricingTipRef}
                  trigger="legacy"
                  placement="auto-end">
                  <PopoverHeader>
                    Your order qualifies for Move-In/On-Site pricing
                  </PopoverHeader>
                  <PopoverBody>
                    Move-In/On-Site Pricing – A 20% Expedite Fee is applied to
                    any order placed 72 hours or less before the listed event
                    start date.
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

ServiceItem.propTypes = {
  service: PropTypes.object.isRequired,
  pricingTier: PropTypes.number.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
};
