import React from 'react';

import * as Sentry from '@sentry/react';
import { useNavigate } from '@/hooks';
import { AppContextProvider } from '@/context';
import { ErrorBoundary, Layout, NotFound } from '@/components';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';

import {
  Home,
  Terms,
  PlaceOrder,
  OrderDetails,
  ContactUs,
  Blog,
  BlogPost,
  Videos,
} from '@/pages';

import './assets/site.scss';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default function App({ baseUrl, rootElement }) {
  return (
    <AppContextProvider value={{ baseUrl, rootElement }}>
      <Layout>
        <ErrorBoundary showDialog>
          <SentryRoutes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />

            <Route path="/location/:locationID">
              <Route index element={<Home />} />
              <Route path="contact" element={<ContactUsRouter />} />
              <Route path="order" element={<OrderRouter />} />
              <Route path="order/step-1" element={<OrderRouter />} />
              <Route path="order/step/:stepNumber?" element={<OrderRouter />} />
            </Route>

            <Route
              path="/order/details/:confirmationNumber"
              element={<OrderDetailsRouter />}
            />

            <Route path="/videos/:slug" element={<Videos />} />
            <Route path="/blog">
              <Route index element={<Blog />} />
              <Route path=":slug" element={<BlogPost />} />
              <Route path="page-:pageNumber" element={<Blog />} />
              <Route path="categories/:category">
                <Route path="" element={<Blog />} />
                <Route path="page-:pageNumber" element={<Blog />} />
              </Route>
            </Route>
          </SentryRoutes>
        </ErrorBoundary>
      </Layout>
    </AppContextProvider>
  );
}

function ContactUsRouter() {
  let { locationID } = useParams();
  locationID = parseInt(locationID);

  return (
    <React.Fragment>
      {!locationID ? <NotFound /> : <ContactUs />}
    </React.Fragment>
  );
}

function OrderRouter() {
  const navigate = useNavigate();

  let { locationID, stepNumber } = useParams();
  locationID = parseInt(locationID);

  if (!locationID) {
    return <NotFound />;
  }

  if (!stepNumber) {
    return <Navigate to={navigate.getPlaceOrderUrl(locationID, 1)} />;
  }

  return <PlaceOrder />;
}

function OrderDetailsRouter() {
  let { confirmationNumber } = useParams();
  confirmationNumber = parseInt(confirmationNumber);

  return (
    <React.Fragment>
      {!confirmationNumber ? <NotFound /> : <OrderDetails />}
    </React.Fragment>
  );
}
