import React from 'react';
import classNames from 'classnames';

import { format } from '@/services';
import { Row, Col } from 'reactstrap';
import { useAppContext } from '@/context';

export default function Footer(props) {
  const { phoneNumber } = useAppContext();

  return (
    <footer className={classNames(props.className, 'mt-6 mb-4')}>
      <Row className="text-center text-size-sm text-medium mb-1">
        <Col>
          {phoneNumber && (
            <p className="d-md-inline-block">
              <label>Phone:</label>{' '}
              <a href={`tel:${format.phoneNumber(phoneNumber, 'plain')}`}>
                {format.phoneNumber(phoneNumber)}
              </a>
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="text-center text-size-sm">
          <p>
            &copy; {new Date().getFullYear()} &mdash; Cox Business
            <br className="d-md-none" /> Advanced Convention Services
          </p>
        </Col>
      </Row>
    </footer>
  );
}
